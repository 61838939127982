import React from "react";
import Logo from "../../../../images/logo.png";
import { NavLink } from "react-router-dom";

// Material UI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Footer = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box display={"flex"} alignItems={"center"} color={"primary.dark"}>
            <Box
              component="img"
              sx={{
                height: 30,
              }}
              alt="Your logo."
              src={Logo}
            />
            <Typography fontWeight={700} marginLeft={1}>
              MED@WORK
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Box marginLeft={2}>
              <NavLink to={"/terms"} style={{ textDecoration: "none" }}>
                <Typography underline={"none"} color="primary">
                  Conditions générales
                </Typography>
              </NavLink>
            </Box>
            <Box marginLeft={2}>
              <NavLink to={"/contactUs"} style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  target="blank"
                  size="small"
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                  }
                >
                  Contactez-nous
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
          gutterBottom
        >
          &copy; MED@WORK. 2021, Brussels. All rights reserved - beta 2.4.6
        </Typography>
        <Typography
          align={"center"}
          variant={"caption"}
          color="text.secondary"
          component={"p"}
        >
          Lorsque vous visitez ou interagissez avec notre site, services ou
          outils, nous pouvons utiliser des cookies pour stocker des
          informations afin de vous offrir une meilleure expérience utilisateur,
          plus rapide et plus sûre.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
