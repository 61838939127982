export const specialityLinks = [
  { value: "anesthesiology", title: "Anesthésiologie" },
  { value: "cardio", title: "Cardiologie" },
  { value: "dig", title: "Chirurgie digestive" },
  { value: "general", title: "Chirurgie générale" },
  { value: "maxillofacial", title: "Chirurgie maxillo-faciale" },
  { value: "oralSurgery", title: "Chirurgie orale" },
  { value: "ortho", title: "Chirurgie orthopédique" },
  { value: "plastic", title: "Chirurgie plastique" },
  { value: "thor", title: "Chirurgie thoracique" },
  { value: "vasc", title: "Chirurgie vasculaire" },
  { value: "dermatology", title: "Dermatologie" },
  { value: "endocrinology", title: "Endocrinologie" },
  { value: "gastro", title: "Gastro-entérologie" },
  { value: "geriatrics", title: "Gériatrie" },
  { value: "gynaeco", title: "Gynécologie et obstétrique" },
  { value: "haematology", title: "Hématologie" },
  { value: "infectiousDisease", title: "Maladies infectieuses" },
  { value: "internalMedecin", title: "Médecine interne" },
  { value: "rehab", title: "Médecine physique et de réadaptation" },
  { value: "nephrology", title: "Néphrologie" },
  { value: "neurosurgery", title: "Neurochirurgie" },
  { value: "neurology", title: "Neurologie" },
  { value: "oncology", title: "Oncologie" },
  { value: "ophthalmology", title: "Ophtalmologie" },
  { value: "otolaryngology", title: "Oto-rhino-laryngologie" },
  { value: "pediatric", title: "Pédiatrie" },
  { value: "pedopsychiatry", title: "Pédopsychiatrie" },
  { value: "pulmonology", title: "Pneumologie" },
  { value: "psychiatry", title: "Psychiatrie" },
  { value: "radiology", title: "Radiologie" },
  { value: "rheumatology", title: "Rhumatologie" },
  { value: "intensiveCare", title: "Soins intensifs" },
  { value: "palliative", title: "Soins palliatifs" },
  { value: "emergency", title: "Urgences" },
  { value: "uro", title: "Urologie" },
];

export const specialityAbreviation = {
  anesthesiology: "Anesthésiologie",
  cardio: "Cardiologie",
  dig: "Chirurgie digestive",
  general: "Chirurgie générale",
  maxillofacial: "Chirurgie maxillo-faciale",
  ortho: "Chirurgie orthopédique",
  plastic: "Chirurgie plastique",
  thor: "Chirurgie thoracique",
  vasc: "Chirurgie vasculaire",
  dermatology: "Dermatologie",
  endocrinology: "Endocrinologie",
  gastro: "Gastro-entérologie",
  geriatrics: "Gériatrie",
  gynaeco: "Gynécologie et obstétrique",
  haematology: "Hématologie",
  infectiousDisease: "Maladies infectieuses",
  internalMedecin: "Médecine interne",
  rehab: "Médecine physique et de réadaptation",
  nephrology: "Néphrologie",
  neurosurgery: "Neurochirurgie",
  neurology: "Neurologie",
  oncology: "Oncologie",
  ophthalmology: "Ophtalmologie",
  otolaryngology: "Oto-rhino-laryngologie",
  pediatric: "Pédiatrie",
  pedopsychiatry: "Pédopsychiatrie",
  pulmonology: "Pneumologie",
  psychiatry: "Psychiatrie",
  radiology: "Radiologie",
  rheumatology: "Rhumatologie",
  intensiveCare: "Soins intensifs",
  palliative: "Soins palliatifs",
  emergency: "Urgences",
  uro: "Urologie",
};

export const gardeTypeList = {
  callable: "Garde appelable",
  hospital: "Garde sur place",
};

export const absenceTypeList = {
  annualLeave: "Congé annuel",
  paidLeave: "Congé ferrié",
  sickLeave: "Congé maladie",
  paternityLeave: "Congé paternité",
  maternityLeave: "Congé maternité",
  scientificLeave: "Congé scientifique",
  casualLeave: "Congé de circonstance",
  unpaidLeave: "Congé non rémunéré",
  recovery: "Récupération",
  compensatoryHolidayLeave: "Récupération de jour férié",
};

export const monthList = {
  1: "JANVIER",
  2: "FEVRIER",
  3: "MARS",
  4: "AVRIL",
  5: "MAI",
  6: "JUIN",
  7: "JUILLET",
  8: "AOUT",
  9: "SEPTEMBRE",
  10: "OCTOBRE",
  11: "NOVEMBRE",
  12: "DECEMBRE",
};

export const jobList = {
  "medical supervisor": "Maître de stage",
  doctor: "Médecin",
  "human resources": "Ressources humaines",
};

export const warningList = {
  minLimit: "Dépassement du maximum autorisé",
  maxLimit: "Dépassement du maximum légal",
  overruns: "Dépassement multiple",
  smoothing: "Lissage sur 13 semaines",
};
