import React, { useState, useContext, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useNotificationContext from "../../hooks/useNotificationContext";

// Material UI
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@mui/styles";

import Container from "../medium/Container";
import { Topbar, Sidebar, Footer } from "./components";
import useNotifications from "../../hooks/useNotifications";

const useStyles = makeStyles((theme) => ({
  root: "100%",
}));

const WithFixedSidebar = ({ children }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  // installation
  const { authentication } = useAuth();

  // use the useNotifications hook to get the notifications
  const { notifications, setNotifications } = useNotificationContext();

  const check = useNotifications(authentication.role);

  useEffect(() => {
    setNotifications({
      count: check?.notifications?.filter(
        (notification) => !notification.isRead
      ).length,
      notifications: check?.notifications,
    });
  }, [check]);

  //const [notifications, setNotifications] = useState(
  //  useNotifications(authentication.role)
  //);
  //console.log(notifications);
  // side bar
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };
  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  const open = isMd ? false : openSidebar;

  return (
    <Box>
      <AppBar
        position={"fixed"}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          zIndex: "999",
        }}
        elevation={0}
      >
        <Topbar onSidebarOpen={handleSidebarOpen} />
      </AppBar>

      {authentication.isAuthenticated && (
        <Sidebar
          onClose={handleSidebarClose}
          open={open}
          variant={isMd ? "permanent" : "temporary"}
        />
      )}

      {!authentication.isAuthenticated & !isMd && (
        <Sidebar
          onClose={handleSidebarClose}
          open={open}
          variant={isMd ? "permanent" : "temporary"}
        />
      )}

      <main>
        <Box height={{ xs: 40, sm: 66, md: 71 }} />
        <Box
          display="flex"
          overflow="hidden"
          paddingLeft={{ md: authentication.isAuthenticated ? "256px" : "0px" }}
        >
          <Box display="flex" overflow="hidden" width="100%">
            <Box height="100%" maxWidth="100%" width="100%">
              <Box minHeight={"100vh"}>{children}</Box>

              <Divider />
              <Container paddingY={4}>
                <Footer />
              </Container>
            </Box>
          </Box>
        </Box>
      </main>
    </Box>
  );
};

export default WithFixedSidebar;
