import axios from "axios";
import { API_URL } from "../config";

/**
 * Create new Resident account
 * @param {obj} resident
 * @returns
 */
function create(resident) {
  return axios.post(API_URL + "create/newResident", resident);
}

/**
 * Update a resident.
 * @returns {obg}
 */
function update() {
  const info = {
    method: "put",
    url: "residents/update",
  };
  return info;
}

function fetchResidents() {
  const info = {
    method: "get",
    url: "managers/GetYearResidents/",
  };
  return info;
}

function fetchResidentInfo() {
  const info = {
    method: "get",
    url: "residents/userInfo",
  };
  return info;
}

function fetcResidentSheduler() {
  const info = {
    method: "get",
    url: "residents/fetchSchedulerData",
  };

  return info;
}

const exportedObject = {
  create,
  update,
  fetchResidents,
  fetchResidentInfo,
  fetcResidentSheduler,
};

export default exportedObject;
