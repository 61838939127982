import axios from "axios";
import jwtDecode from "jwt-decode";
import { API_URL, LOGGIN_API } from "../config";

/**
 * Requête HTTP d'authentification et stockage dans le storage et sur Axios
 * @param {object} credentials
 */
const authenticate = (credentials) => {
  return axios
    .post(LOGGIN_API, credentials, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
    .then((response) => response.data);
};

/**
 *
 */
const logout = () => {
  const info = {
    method: "get",
    url: "logout",
  };
  return info;
};

/**
 * Permet de détermniner si l'utilisateur est connecté.
 * @return boolean
 */
function isAuthenticated() {
  const token = window.localStorage.getItem("authToken");

  if (token) {
    const { exp: expiration } = jwtDecode(token);
    if (expiration * 1000 > new Date().getTime()) {
      return true;
    }
    return false;
  }
  return false;
}

/**
 * Récupère le nom et prénom de l'utilisateur pour le stocker dans UserName.
 * @var string
 */
function getUser() {
  const data = window.localStorage.getItem("user");

  if (data) {
    //const check = jwtDecode(token);
    const user = {
      firstname: data.firstname,
      lastname: data.lastname,
      role: data.role,
      gender: data.gender,
    };
    return user;
  }
}

/**
 * Positionne le token JWT sur Axios
 * @param {string} token Le token JWT
 */
function setAxiosToken(token) {
  axios.defaults.headers["Authorization"] = "Bearer " + token;
}

/**
 * Mise en place lors du chargement de l'application
 */
function setup() {
  const token = window.localStorage.getItem("authToken");

  if (token) {
    const { exp: expiration } = jwtDecode(token);
    if (expiration * 1000 > new Date().getTime()) {
    }
  }
}

/**
 * Reset password with token
 */
const resetPassword = (info) => {
  return axios
    .post(API_URL + "passwordResetWithToken", info, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
    .then((response) => response.data);
};

const exportedObject = {
  authenticate,
  setup,
  isAuthenticated,
  getUser,
  logout,
  resetPassword,
};

export default exportedObject;
