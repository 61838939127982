import axios from "axios";
import { API_URL, MANAGERS_API } from "../config";

/**
 * Create new Manager account
 * @param {obj} Manager
 * @returns
 */
function create(manager) {
  return axios.post(API_URL + "create/newManager", manager, {
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
}

function fetchManagers() {
  const info = {
    method: "get",
    url: "fetchManagers",
  };
  return info;
}

function fetchStaffPlannerList() {
  const info = {
    method: "get",
    url: MANAGERS_API + "/getSPRes/",
  };
  return info;
}

function updateStaffPlannerList() {
  const info = {
    method: "put",
    url: MANAGERS_API + "/updateSPRes",
  };
  return info;
}

function updateResidentValidationPeriod() {
  const info = {
    method: "put",
    url: MANAGERS_API + "/validation/",
  };
  return info;
}

const exportedObject = {
  create,
  fetchManagers,
  fetchStaffPlannerList,
  updateStaffPlannerList,
  updateResidentValidationPeriod,
};

export default exportedObject;
