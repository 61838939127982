import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { frFR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";

import "dayjs/locale/fr";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
  },
}));

const frenchLocale =
  frFR.components.MuiLocalizationProvider.defaultProps.localeText;

export default function DateHandler({
  value,
  label,
  onChange,
  error = false,
  helperText,
  size = "large",
}) {
  const classes = useStyles();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={frenchLocale}
      adapterLocale="fr"
    >
      <DatePicker
        label={label}
        className={classes.root}
        value={dayjs(value)}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        slotProps={{
          textField: {
            helperText: helperText,
            error: error,
          },
        }}
        renderInput={(params) => (
          <TextField size={size} fullWidth {...params} />
        )}
      />
    </LocalizationProvider>
  );
}
