import axios from "axios";
import { API_URL } from "../config";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuthentication } = useAuth();

  const refresh = async () => {
    const response = await axios.get(API_URL + "token/refresh", {
      withCredentials: true,
    });
    setAuthentication((prev) => {
      return {
        ...prev,
        isAuthenticated: true,
        AccessToken: response.data.token,
        firstname: response.data.firstname,
        lastname: response.data.lastname,
        role: response.data.role,
        gender: response.data.gender,
      };
    });
    return response.data.token;
  };

  return refresh;
};

export default useRefreshToken;
