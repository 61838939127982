import React from "react";
import useAuth from "../../../../hooks/useAuth";
import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../../images/logo.png";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

// Material UI
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import AuthApi from "../../../../services/AuthAPI";

// Local component
import Woman from "../../../../images/icons/Woman.png";
import Man from "../../../../images/icons/Man.png";

const useStyles = makeStyles((theme) => ({
  linkText: {
    textDecoration: "none",
    textTransform: `uppercase`,
    color: `#2d3748`,
  },
}));

const mock = [
  {
    title: "Overview",
    href: "#",
  },
  {
    title: "Analytics",
    href: "#",
  },
  {
    title: "Automation",
    href: "#",
  },
];

const Topbar = ({ onSidebarOpen }) => {
  const axiosPrivate = useAxiosPrivate();
  const theme = useTheme();
  const classes = useStyles();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  // initialization

  const { authentication, setAuthentication } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const { method, url } = AuthApi.logout();
    setAuthentication({});
    navigate("/login");
    axiosPrivate[method](url);
  };

  return (
    <Box
      maxWidth="100%"
      height={{ xs: 58, sm: 66, md: 71 }}
      display="flex"
      justifyContent="space-between"
      paddingRight={{ xs: 2, sm: 3, md: 4 }}
      paddingLeft={{ xs: 2, sm: 3, md: 4 }}
    >
      <Box display={"flex"} alignItems={"center"} color={"primary.dark"}>
        <Box
          component="img"
          sx={{
            height: 30,
          }}
          alt="Your logo."
          src={Logo}
        />
        <Typography fontWeight={700} marginLeft={1}>
          MED@WORK
        </Typography>
      </Box>

      <Box display={"flex"} alignItems={"center"}>
        {!authentication.isAuthenticated & isMd && (
          <>
            <NavLink
              to="/description"
              className={classes.linkText}
              sx={{ marginRight: theme.spacing(2) }}
            >
              <Button
                variant="text"
                color="primary"
                sx={{ marginRight: theme.spacing(2) }}
                disabled={false}
              >
                Notre service
              </Button>
            </NavLink>
            <NavLink
              to="/login"
              className={classes.linkText}
              sx={{ marginRight: theme.spacing(2) }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ marginRight: theme.spacing(2) }}
              >
                Se connecter
              </Button>
            </NavLink>
            <NavLink to="/connecting" className={classes.linkText}>
              <Button variant="outlined" color="primary">
                S'enregistrer
              </Button>
            </NavLink>
          </>
        )}
        {authentication.isAuthenticated & isMd && (
          <>
            <Box
              display="flex"
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                marginRight={"16px"}
              >
                <Stack sx={{ marginRight: "6px" }}>
                  <Avatar
                    src={
                      authentication && authentication?.gender === "male"
                        ? Man
                        : Woman
                    }
                    sx={{ width: 35, height: 35 }}
                  />
                </Stack>
                <Typography color="primary">
                  {authentication &&
                    authentication?.firstname + " " + authentication?.lastname}
                </Typography>
              </Box>

              <Button
                variant="outlined"
                color="primary"
                onClick={handleLogout}
                className={classes.linkText}
              >
                Se déconnecter
              </Button>
            </Box>
          </>
        )}

        <Box sx={{ display: { xs: "block", md: "none" } }} marginLeft={2}>
          <Button
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
            variant={"outlined"}
            sx={{
              borderRadius: 2,
              minWidth: "auto",
              padding: 1,
              borderColor: alpha(theme.palette.divider, 0.2),
            }}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
