//export const API_URL = "http://localhost:8000/api/";
export const API_URL = "https://api-link.medatwork.be/api/";
//export const API_URL = "https://back-maw.easymed.fun/api/";

export const LOGGIN_API = API_URL + "login_check";

export const YEARS_API = API_URL + "years";

export const MANAGERS_API = API_URL + "managers";

export const RESIDENTS_API = API_URL + "residents";

export const PERIODS_API = API_URL + "periods";

export const TIMESHEET_API = API_URL + "timesheets";

export const GARDE_API = API_URL + "gardes";

export const ABSENCE_API = API_URL + "absences";

export const MANAGER_API = API_URL + "manager";
