import { MANAGERS_API, RESIDENTS_API } from "../config";

function GetManagerNotifications() {
  const info = {
    method: "get",
    url: MANAGERS_API + "/notifications/unread",
  };
  return info;
}

function NotificationManagerMarkAllAsAeaderNotifications() {
  const info = {
    method: "get",
    url: MANAGERS_API + "/notifications/mark-all-as-read",
  };
  return info;
}

function GetResidentNotifications() {
  const info = {
    method: "get",
    url: RESIDENTS_API + "/notifications/unread",
  };
  return info;
}

function NotificationResidentMarkAllNotificationsAsReaded() {
  const info = {
    method: "get",
    url: RESIDENTS_API + "/notifications/mark-all-as-read",
  };
  return info;
}

const exportedObject = {
  GetManagerNotifications,
  NotificationManagerMarkAllAsAeaderNotifications,
  GetResidentNotifications,
  NotificationResidentMarkAllNotificationsAsReaded,
};

export default exportedObject;
