import { useState, useEffect } from "react";
import NotificationsAPI from "../services/NotificationsAPI";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useNotifications = (role) => {
  const [notifications, setNotifications] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const fetchNotifications = async () => {
      if (role === "manager") {
        try {
          const { method, url } = NotificationsAPI.GetManagerNotifications();
          const request = await axiosPrivate[method](url);
          setNotifications(request?.data);
        } catch (error) {
          console.log(error.message);
        }
      }
      if (role === "resident") {
        try {
          const { method, url } = NotificationsAPI.GetResidentNotifications();
          const request = await axiosPrivate[method](url);
          setNotifications(request?.data);
        } catch (error) {
          console.log(error.message);
        }
      }
    };

    const intervalId = setInterval(fetchNotifications, 30000);
    fetchNotifications();

    return () => clearInterval(intervalId);
  }, [role]);

  return notifications;
};

export default useNotifications;
