import { createTheme } from "@mui/material/styles";
import { purple } from "@mui/material/colors";

export const CustomizedTheme = createTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: "#46dbc9",
    },
    alternate: {
      main: "#f7faff",
    },
    purple: {
      main: "#9155FD",
    },
    white: {
      main: "#ffffff",
    },
    textButton: {
      main: "#fff",
    },
    error: {
      main: "#FF4C51",
    },
    warning: {
      main: "#FFB400",
    },
    info: {
      main: "#16B1FF",
    },
    success: {
      main: "#56CA00",
    },
  },
});
